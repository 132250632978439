import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useGetUserId = () => {
  const [userId, setUserId] = useState("");
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const getUserId = () => {
      const token = localStorage.getItem("token") as string;
      if (!token || token === "undefined" || typeof token === "undefined") return setUserId("");
      try {
        setUserId(token);
      } catch {
        return setUserId("");
      }
    };
    getUserId();
  }, [userId, pathname]);

  return userId;
};
export default useGetUserId;
