import {
  Divider,
  Stack,
  Typography,
  Dialog,
  IconButton,
  Box,
} from "@mui/material";
import { useContext, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import Button from "../../../../components/Button";
import Label from "../../../../components/Label";
import Input from "../../../../components/Input";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";
import sendErrorToast from "../../../../utils/sendErrorToast";
import fetcher from "../../../../utils/fetchWrapper";
import formatPhoneNumber from "../../../../utils/formatPhoneNumber";
import { endpoints } from "../../../../api/constants/endpoints";
import {
  Step1Type,
  Step2Type,
  Step3Type,
  DeviceStepType,
} from "../../AccountSettings";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../components/AddressAndAccountProvider";
import {
  headerStyle,
  dividerStyle,
  getSubHeaderStyle,
  iconStyle,
} from "./Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface DeviceStepProps {
  changeStep: (args: DeviceStepType) => void;
  setDeviceId: React.Dispatch<React.SetStateAction<string>>;
  previousValues: {
    step1: Step1Type;
    step2: Step2Type;
    step3: Step3Type;
    deviceStep: DeviceStepType;
  };
  goBackOneStep: () => void;
}

const DeviceStep = ({
  changeStep,
  previousValues,
  goBackOneStep,
  setDeviceId,
}: DeviceStepProps) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const { provider } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const [isLoading, setIsLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const operatorName = useMemo(
    () => provider?.data?.provider?.name,
    [provider]
  );
  const operatorNumber = useMemo(() => {
    const support = provider?.data?.provider?.Contacts?.find(
      (e) => e.type === "SUPPORT"
    );
    return (
      support?.phone ?? provider?.data?.provider?.Contacts?.[0]?.phone ?? ""
    );
  }, [provider]);

  const {
    watch,
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<DeviceStepType>({
    mode: "onChange",
    defaultValues: {
      ...previousValues.deviceStep,
    },
  });

  const deviceSerial = watch("deviceSerial");

  const disableButton = !isValid;

  const fail = (message: string) => {
    setIsLoading(false);
    sendErrorToast(message);
  };

  const onSubmit = (deviceStep: DeviceStepType) => {
    setIsLoading(true);

    // Find the device with the matching serial number
    fetcher(
      `${endpoints.deviceSearch}?serial_number=${deviceSerial}&loading=Accounts&limit=1&filter=status EQ ACTIVE::serial_number LIKE ${deviceSerial}`
    ).then(async (res) => {
      const deviceResponse = await res.json();
      if (res.status !== 200) {
        fail(deviceResponse.message);
        return;
      }

      // Get device from response
      const device = deviceResponse?.data?.device?.devices?.[0];
      if (!device) {
        fail("No device with that serial number was found");
        return;
      }

      // Device should not belong to another account
      if (device.Accounts.length) {
        fail("This device is already in use with another account");
        return;
      }

      setIsLoading(false);
      setDeviceId(device.id);
      changeStep(deviceStep);
    });
  };

  return (
    <>
      <Stack spacing={getREMFromPX(theme.spacing * 2)}>
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          style={{
            position: "absolute",
            alignSelf: "flex-end",
            width: 30,
            height: 30,
            marginLeft: 5,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setShowDialog(true);
          }}
        >
          <FontAwesomeIcon
            icon="info"
            style={iconStyle}
            fill={colors.icons.default.fillColor}
          />
        </IconButton>
        <Typography sx={headerStyle} component="h1">
          Device
        </Typography>
        <Typography sx={getSubHeaderStyle(themePath)}>
          Connect a device
        </Typography>
      </Stack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          spacing={getREMFromPX(theme.spacing * 3)}
          mt={getREMFromPX(theme.spacing * 8)}
        >
          <Label htmlFor="deviceSerial">Device Serial Number</Label>
          <Input
            data-testid="deviceSerialTest"
            {...register("deviceSerial", {
              required: true,
              pattern: {
                value: /^[a-zA-Z0-9]{1,30}$/,
                message: "Invalid serial number",
              },
            })}
          />
        </Stack>
        <Divider sx={dividerStyle} />
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          mt={getREMFromPX(theme.spacing * 6)}
        >
          <Button
            startIcon={
              <FontAwesomeIcon
                icon="angle-left"
                style={iconStyle}
                fill={colors.icons.default.fillColor}
              />
            }
            onClick={goBackOneStep}
            variant="text"
            text="Back"
            size="medium"
          />
          <Button
            data-testid="continueButton"
            text="Continue"
            type="submit"
            size="medium"
            isLoading={isLoading}
            disabled={disableButton || isLoading}
          />
        </Stack>
      </form>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <Box
          padding={getREMFromPX(theme.spacing * 8)}
          maxWidth={getREMFromPX(theme.spacing * 150)}
          data-testid="dialogWrapper"
        >
          <Typography mb={getREMFromPX(theme.spacing * 6)}>
            A device must be installed on the premises before an account can be
            created. If you do not have a device, please reach out to&nbsp;
            <span style={{ whiteSpace: "nowrap" }}>{operatorName}</span>{" "}
            at&nbsp;
            <span style={{ whiteSpace: "nowrap" }}>
              {formatPhoneNumber(operatorNumber)}
            </span>{" "}
            to schedule your installation
          </Typography>
          <Typography>
            The serial number can be found on the back side of the device or on
            the box under a barcode. It will begin with a P.
          </Typography>
        </Box>
      </Dialog>
    </>
  );
};

export default DeviceStep;
